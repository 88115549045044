import { Product } from "@akinon/next/types";

export const swanLogin = (session:any) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.login) {
    window._swan.login(session?.user?.pk)
  }
}

export const swanLogout = () => {
  if (typeof window !== 'undefined' && window._swan && window._swan.logout) {
    window._swan.logout();
  }
}

export const swanPageView = (url:string) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.screen) {
    window._swan.screen({ "screenName": url });
  }
}

export const swanProductView = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productViewed) {
    window._swan.productViewed({
      "productId": product?.pk
      });
  }
}

export const swanProductClicked = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productClicked) {
    window._swan.productClicked({
      "productId": product?.pk
    });
  }
}

export const swanAddWishlist = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productAddedToWishlist) {
    window._swan.productAddedToWishlist({
      "productId": product?.pk
    });
  }
}

export const swanRemoveWishlist = (product:Pick<
  Product,
  'pk'
>) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productRemovedFromWishlist) {
    window._swan.productRemovedFromWishlist({
      "productId": product?.pk
    });
  }
}

export const swanPushAddToCart = (product: Product) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productAddedToAddTocart) {
    window._swan.productAddedToAddTocart({
      "productId": product?.pk,
      "quantity": product?.quantity || 1
    });
  }
}

export const swanRemoveFromCart = (product: Product) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.productRemovedFromAddToCart) {
    window._swan.productRemovedFromAddToCart({
      "productId": product?.pk,
      "quantity": 0
    });
  }
}

export const swanShare = (pk:string, media:string ) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.share) {
    window._swan.share({
      "productId" : pk,
      "media": media || ''
      });
  }
}

export const swanForgotPassword = () => {
  if (typeof window !== 'undefined' && window._swan && window._swan.forgotPassword) {
    window._swan.forgotPassword({ "success": true });
  }
}

export const swanSearch = (searchTerm:string) => {
  if (typeof window !== 'undefined' && window._swan && window._swan.search) {
    window._swan.search({
      "searchKeyword": searchTerm
    });
  }
}

export const swanCheckoutStarted = (preOrder:any, session:any) => {
  const checkoutId = session?.user?.phone;
  const totalAmount = preOrder?.totalAmount;
  const products = preOrder?.basket?.basketitem_set?.map((item:Product) => ({
    "productId": item?.product?.pk,
    "quantity": item?.quantity,
    "price": item?.product?.price
    }));

  if (typeof window !== 'undefined' && window._swan && window._swan.checkoutStarted) {
    window._swan.checkoutStarted({
      "checkoutId": checkoutId,
      "totalAmount": totalAmount,
      "productIds": products
    });
  }
}

export const swanCheckoutCanceled = (preOrder:any, session:any) => {
  const checkoutId = session?.user?.phone;
  const totalAmount = preOrder?.totalAmount;
  const products = preOrder?.basket?.basketitem_set?.map((item:Product) => ({
    "productId": item?.product?.pk,
    "quantity": item?.quantity,
    "price": item?.product?.price
    }));

  if (typeof window !== 'undefined' && window._swan && window._swan.checkoutCanceled) {
    window._swan.checkoutCanceled({
      "checkoutId": checkoutId,
      "totalAmount": totalAmount,
      "productIds": products
    });
  }
}

export const swanCheckoutCompleted = (order:any, session:any) => {
  const checkoutId = session?.user?.phone;
  const totalAmount = order?.amount;

  const groupedItems = order.orderitem_set.reduce((acc, item) => {
    const itemId = item.product.pk;

    if (!acc[itemId]) {
      acc[itemId] = {
        ...item,
        quantity: 1,
      };
    } else {
      acc[itemId].quantity += 1;
    }

    return acc;
  }, {});

  const products = Object.values(groupedItems).map((item:Product) => ({
    "productId": item?.product?.pk,
    "quantity": item?.quantity,
    "price": item?.price
    }));

  if (typeof window !== 'undefined' && window._swan && window._swan.checkoutCompleted) {
    window._swan.checkoutCompleted({
      "checkoutId": checkoutId,
      "orderId": order?.id,
      "orderNumber": order?.number,
      "totalAmount": totalAmount,
      "productIds": products
    });
  }
}
